<template>
  <div class="user-manage">
    <table-search :total="total" :conditions="conditions" @searchRes="searchRes" @refresh="getList">
    </table-search>
    <div class="table">
      <el-table
        :data="tableData"
        border
        style="width: 100%">
        <el-table-column
          prop="id"
          label="短信ID">
        </el-table-column>
        <el-table-column
          prop="content"
          label="发送内容">
        </el-table-column>
        <el-table-column
          prop="templateContent"
          label="发送类型">
          <template slot="header">
            <single-selector :filters="subjectFilter" text="发送类型" @command="commandHandle"></single-selector>
          </template>
          <template slot-scope="scope">
            <span :style="{'color':COLOR.blue}">{{scope.row.templateContent}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="id"
          label="触发相关ID">
        </el-table-column>
        
        <el-table-column
          prop="phone"
          label="接收手机">
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="发送时间">
        </el-table-column>
        <el-table-column
          prop="status"
          column-key="status"
          label="状态">
          <template slot="header">
            <single-selector :filters="statusFilter" text="状态" @command="commandStatus"></single-selector>
          </template>
          <template slot-scope="scope">
            <span :style="{'color':COLOR.green}" v-show="scope.row.status=='000'">发送成功</span>
            <span :style="{'color':COLOR.red}" v-show="scope.row.status=='111'">发送失败</span>
          </template>
        </el-table-column>
        <!-- <el-table-column
          width="180"
          prop="val"
          label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="checkHandle(scope.row)">入回参备注</el-button>
          </template>
        </el-table-column> -->
      </el-table>
    </div>
    <div class="footer flex-row">
      <div class="page">
        <el-pagination
          background
          :page-size="form.perPage"
          :current-page="form.page"
          @size-change="handleSizeChange"
          layout="prev, pager, next,sizes,jumper"
          :page-sizes="[10, 20, 30, 40]"
          @current-change="currentChange"
          :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import TableSearch from '../../components/table-search.vue'
import {getSmsRecordList} from '../../api/service'
import SingleSelector from '../../components/table/single-selector.vue'
import {initSmsContent} from '../../api/open'
export default {
  name:'shortMessageRecord',
  data () {
    return {
      total:0,
      form:{
        page:1,
        perPage:10,
        status:'',
        templateContent:''
      },
      conditions:[
        {value:'phone',label:'接收手机'},
        {value:'id',label:'相关ID'},
        {value:'content',label:'短信内容'},
      ],
      tableData:[
       
      ],
      multipleSelection:[],
        statusFilter:[
          {text: '正常', value: '1'}, 
          {text: '禁言', value: '2'}, 
          {text: '禁止登陆', value: '3'}
        ],
        applyFilter:[
          {text: '诉讼业务', value: '1'}, 
          {text: '婚姻家庭', value: '2'}, 
          {text: '合同纠纷', value: '3'},
          {text: '非诉讼业务', value: '4'},
          {text: '法律顾问', value: '5'},
          {text: '劳动纠纷', value: '6'},
          {text: '金融证券', value: '7'},
          {text: '公司经营', value: '8'},
          {text: '法律文书', value: '9'},
        ],
        typeFilter:[
            {text: '等待选标中', value: '1'},
            {text: '已中标', value: '2'}, 
            {text: '未中标', value: '3'}
        ],
        subjectFilter:[
          {text: '全部', arrValue: ['']},
          {text: '发送短信验证码', arrValue: ['发送短信验证码']},
          {text: '登陆验证', arrValue: ['登陆验证码']}, 
          {text: '注册验证码', arrValue: ['注册验证码']}, 
          {text: '找回密码', arrValue: ['找回密码']}, 
          {text: '默认密码', arrValue: ['默认密码']}
        ],
        statusFilter:[
            {text: '全部', arrValue: ['']},
            {text: '发送成功', arrValue: ['000']},
            {text: '发送失败', arrValue: ['111']}, 
        ]

    };
  },
  components: {
    TableSearch,
    SingleSelector
  },
  computed: {},
  methods: {
    commandHandle(command){
      this.form.templateContent = command && command[0];
      this.form.page = 1;
      this.getList();
    },
    commandStatus(command){
      this.form.status = command && command[0];
      this.form.page = 1;
      this.getList();
    },
    searchRes(para){
      this.form = Object.assign({
        page:1,
        perPage:this.form.perPage,
        status:this.form.status,
        templateContent:this.form.templateContent
        },para);
      this.getList();
    },
    getList(){
      getSmsRecordList(this.form).then(res=>{
        let data =res.data;
        if(data.code ==='000'){
          this.tableData = data.content.list;
          this.total = data.content.total;

        }else{
          console.log(data.desc)
        }
      })
    },
    // 分页
    currentChange(val){
      this.form.page = val;
      this.getList();
    },
    handleSizeChange(val){
      this.form.perPage = val;
      this.form.page = 1;
      this.getList();
    },
    checkHandle(row){}
  },
  created(){
    this.getList();
  }
}

</script>
<style lang='scss' scoped>
.footer{
  justify-content: flex-end;
  margin:15px 0;
}
.dot{
  font-size: 20px;
  padding-right: 5px;
  vertical-align:middle;
}
</style>